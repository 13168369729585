<template>
  <div class="main">
    <page-top :title="siteName"></page-top>
    <div class="tools">
      <div>
        <div class="pre-back">
          <img :src="img.up" @click="prePage(true)" />
          <img :src="img.bottom" @click="prePage(false)" />
        </div>

        <button :class="{ button: true, forbidden: !addAble }" @click="setCurretnTypeByAddBtn">
          {{ !adding ? '新 建' : '取 消' }}
        </button>

        <div class="color-set">
          <div :class="{ 'type-one': true, 'type-selected': currentType == timerTypes.one }" @click="setCurretnType(timerTypes.one)">单 次</div>
          <div :class="{ 'type-day': true, 'type-selected': currentType == timerTypes.day }" @click="setCurretnType(timerTypes.day)">每 天</div>
          <div :class="{ 'type-week': true, 'type-selected': currentType == timerTypes.week }" @click="setCurretnType(timerTypes.week)">每 周</div>
          <div :class="{ 'type-month': true, 'type-selected': currentType == timerTypes.month }" @click="setCurretnType(timerTypes.month)">每 月</div>
          <div style="width: 80px">
            <button v-if="isEditing" @click="doCancel" :class="{ button: true }">取消编辑</button>
          </div>
          <div style="width: 80px">
            <button v-if="isEditing" @click="doNext" :class="{ button: true }">下一步</button>
          </div>
        </div>
      </div>
    </div>
    <div class="timer" id="table">
      <div class="title">
        <div class="title-week" :data-day="item.day" :data-time="item.time" :key="'week-' + index" v-for="(item, index) in weekDays">
          {{ item.text }}
        </div>
      </div>

      <div class="all" ref="scrollView">
        <div class="times">
          <!-- o-24点的刻度，间隔30分钟 -->
          <div v-for="(item, index) in clocks48" class="clock-unit" :key="'clock-unit-' + index">
            {{ item }}
          </div>
        </div>
        <div class="jobs" ref="taskView" @click="clickTaskViews">
          <!-- xy轴的所有已经执行过的任务格  -->
          <div v-for="(item, index) in invalidList" :data-day="item.day" :data-hour="item.hour" :data-min="item.min" class="type-one task-clock-one task-invalid" :key="'clock_invalid_' + index" :data-index="index" :style="getStyle(item)" :data-type="unitType.task" :data-task="item.type">
            <div class="type-one task-clock-one task-invalid task-invalid-block" :key="'clock_invalid_block_' + index" :data-index="index" :data-type="unitType.task" :data-task="item.type"></div>

            <p>
              <span class="task-success" v-if="item.state === 5">【成功】</span>
              <span class="task-fail" v-else>【失败】</span>
              {{ item.name }}
              <span class="task-time">
                {{ '(' + (item.hour > 9 ? item.hour : '0' + item.hour) + ':' + (item.min > 9 ? item.min : '0' + item.min) + (item.endTime ? item.endTime : '') + ')' }}
              </span>
              <span class="task-source">{{ getSource(item) }}</span>
            </p>
          </div>

          <!-- xy轴的所有任务格  -->
          <div v-for="(item, index) in list" :data-day="item.day" :data-date="item.date" :data-hour="item.hour" :data-min="item.min" :class="getBaseClassByType(item)" :key="'clock_' + index" :data-index="index" :style="getStyle(item)" :data-type="unitType.task" :data-task="item.type">
            <p>
              {{ item.name + '(' + (item.hour > 9 ? item.hour : '0' + item.hour) + ':' + (item.min > 9 ? item.min : '0' + item.min) + ')' }}
            </p>
          </div>
          <!-- xy轴的所有单元格  星期*时间  空闲 -->
          <div v-for="index in unit48" class="job-one" :key="'job-one-' + index">
            <div v-for="(item, idx) in weekDays" :class="'job-one-ceil ' + 'row-' + index + ' column-' + idx" :key="'week-' + idx" :data-type="unitType.free" :data-start="index" :data-time="item.time" :data-day="item.day" :title="index | indexTilte"></div>
          </div>

          <div v-show="!isEditing && selectedTaskIndex > -1" class="update-delete" :style="updateDeleteStyle">
            <div v-if="isSelectedActive" @click="disabledOne" title="禁 用">
              <i class="el-icon-remove-outline"></i>
            </div>
            <div v-if="isSelectedDisabled" @click="activeOne" title="启 用">
              <i class="el-icon-circle-check"></i>
            </div>
            <div @click="deleteOne" title="删 除"><i class="el-icon-delete"></i></div>
            <div @click="beginEdit" title="编 辑"><i class="el-icon-edit"></i></div>
            <div @click="doCancel" title="取 消"><i class="el-icon-close"></i></div>
          </div>
        </div>
      </div>
    </div>

    <el-form :rules="rules" ref="dataForm" :model="tempTask">
      <el-dialog :close-on-click-modal="false" title="计划任务" :visible.sync="dialogVisible" width="600px" :before-close="cancelData">
        <el-form-item label="计划任务" class="offset-label" prop="missionID">
          <el-row>
            <el-col :span="16">
              <el-select style="width: 100%" placeholder="请选择任务" v-model="tempTask.missionID">
                <el-option :key="'mission_' + index" v-for="(item, index) in missionList" :value="item.missionID" :label="item.missionName"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item required label="执行时间" class="offset-label">
          <el-row v-if="currentType != timerTypes.one">
            <el-col :span="4">
              <el-select v-model="currentType" disabled>
                <el-option v-for="(item, index) in timerTypeCN" :key="'timerTypeCN_' + index" :value="item.key" :label="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :span="12" style="display: flex">
              <el-input v-if="currentType == timerTypes.week" disabled :value="selectedWeekDays"></el-input>
              <el-input v-if="currentType == timerTypes.month" disabled :value="selectedDates"></el-input>
              <el-time-select
                v-model="onceTime"
                :picker-options="{
                  start: HMStart,
                  step: '00:01',
                  end: HMStop,
                }"
                placeholder="选择时间"
                @change="HSchangedHandler"
              ></el-time-select>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="16" style="display: flex">
              <!-- 单次类型  -->
              <el-input disabled :value="onceDate"></el-input>
              <el-time-select
                v-model="onceTime"
                :picker-options="{
                  start: HMStart,
                  step: '00:01',
                  end: HMStop,
                }"
                placeholder="选择时间"
                @change="HSchangedHandler"
              ></el-time-select>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item required label="执行区间" class="offset-label" v-if="currentType != timerTypes.one">
          <el-row v-if="currentType != timerTypes.one">
            <el-date-picker :editable="false" v-model="startToEnd" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" range-separator="~"></el-date-picker>
          </el-row>
        </el-form-item>

        <el-form-item label="任务备注" prop="name">
          <el-row>
            <el-col :span="16">
              <el-input type="textarea" v-model="tempTask.content" placeholder=""></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelData">取 消</el-button>
          <el-button type="primary" @click="sendData">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>

    <el-dialog :close-on-click-modal="false" title="飞行记录信息" :visible.sync="dialogVisible2" width="30%" :before-close="scheduleDialogClose" class="schedule">
      <el-row>
        <el-col :span="12">
          <span>
            任务名称:
            <span>{{ schedule.missionName }}</span>
          </span>
        </el-col>
        <el-col :span="12">
          <span>
            站点名称:
            <span>{{ schedule.siteName }}</span>
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <span>
            起飞时间:
            <span>{{ schedule.UAVStartTime }}</span>
          </span>
        </el-col>
        <el-col :span="12">
          <span>
            降落时间:
            <span>{{ schedule.UAVEndTime }}</span>
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <span>
            执行情况:
            <span>{{ schedule.state }}</span>
          </span>
        </el-col>
        <el-col :span="12">
          <span>
            任务源:
            <span>{{ schedule.missionSource }}</span>
          </span>
        </el-col>
      </el-row>
      <!-- <el-row>x
        <el-col :span="12">
          <span> 飞行里程:<span>{{(schedule.UAVFlightMileage/1000).toFixed(2)}} 千米</span></span>
        </el-col>
        <el-col :span="12">
          <span> 飞行时间: <span>{{Math.floor(schedule.UAVFlightTime/60)}} 分钟，{{schedule.UAVFlightTime %60}}秒</span></span>
        </el-col>
      </el-row> -->

      <el-row>
        <el-col :span="12">
          <span>
            创建时间:
            <span>{{ schedule.createTime }}</span>
          </span>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="删除计划任务" :visible.sync="delDialogVisible" width="30%" :before-close="delDialogClose" class="schedule">
      <el-row>
        <el-switch v-model="deleteAll" active-text="删除关联的计划任务" inactive-text="仅删除此次" @change="changeDeleteSingle"></el-switch>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogClose">取 消</el-button>
        <el-button type="primary" @click="deleteAction">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMissionBySiteId, getMissionDetail } from '@/api/mission';
import { getSiteDetail } from '@/api/site';
import { getTimerListByTimeAndSID, getHistoryListByTimeAndSID, addTimer, updateTimer, deleteTimer, activeTimer } from '@/api/timer';
import PageTop from '../flightpath/children/pageTopBar.vue';
import statusInclude from '@/utils/statusCode';

const timerTypes = {
  one: 1,
  day: 2,
  week: 3,
  month: 4,
};
const timerTypeCN = [
  { key: timerTypes.one, value: '单次' },
  { key: timerTypes.day, value: '每天' },
  { key: timerTypes.week, value: '每周' },
  { key: timerTypes.month, value: '每月' },
];
const ONEDAY_SECOND_M = 24 * 60 * 60 * 1000;

export default {
  components: {
    PageTop,
  },
  props: {},
  data() {
    return {
      siteID: null,
      siteName: '计划任务',
      ceilWidth: 240, // 单元格宽，自适应
      curentLeft: 0, // 区块编辑按钮
      curentTop: 0, // 区块编辑按钮
      startDate: 0,
      endDate: 0,
      selectedTaskIndex: -1, // 选中的编辑区块（单个任务）
      selectedTaskEditDay: -1, // 被编辑的星期
      selectedTaskEditDate: -1, // 被编辑的日期
      isEditing: false, // 是否正在编辑
      editTarget: null, // 编辑时可以拖动的区块
      editTargetMatchObj: null,
      timerTypeCN: timerTypeCN,
      schedule: {},
      unitType: {
        free: 'free', // 空闲的单元格
        task: 'task', // 存在任务的单元格
      },
      baseLeft: 60,
      baseTop: 170,
      onceDate: '',
      datatimeSelected: '',
      onceTime: '00:00',
      dialogVisible: false,
      dialogVisible2: false,
      delDialogVisible: false,
      deleteAll: false,
      adding: false, // 正在新建
      addAble: true, // 当前时间区间内是否可以新建
      currentType: null,
      timerTypes: timerTypes,
      startToEnd: '',
      currentDate: null,
      currentDateInit: null,
      img: {
        up: require('@/assets/images/timer/up.png'),
        bottom: require('@/assets/images/timer/bottom.png'),
      },
      list: [],
      invalidList: [],
      baseList: [],
      missionList: [],
      tempTask: {},
      HMStart: '00:00',
      HMStop: '23:59',
    };
  },

  watch: {
    startToEnd(v) {
      this.startDate = this.startToEnd[0];
      this.endDate = this.startToEnd[1];
    },
    datatimeSelected(v) {
      let date = new Date(v);
      date.setHours(this.tempTask.hour);
      date.setMinutes(this.tempTask.min);
      let startDate = date.getTime();
      let endDate = startDate + ONEDAY_SECOND_M * 30;
      if (this.currentType == this.timerTypes.month) {
        endDate = startDate + ONEDAY_SECOND_M * 90;
      }
      this.startToEnd = [startDate, endDate];
    },

    rules(v) {},
  },
  created() {
    this.initData();
    this.siteID = this.$route.query.siteID;
    this.currentDate = this.get0ClockTimeStempToday();
    this.currentDateInit = this.get0ClockTimeStempToday();
    this.startDate = this.get0ClockTimeStempToday();
    this.endDate = this.startDate + ONEDAY_SECOND_M * 30;
    this.startToEnd = [this.startDate, this.endDate];
    this.getMissionList();
    this.getSiteDetail();
    this.getHistoryList();
    this.getTimerList();
  },

  mounted() {
    this.baseLeft = 60;
    this.baseTop = 170;
    this.initMouseAction();
    window.onresize = () => {
      this.ceilWidth = this.$refs.taskView.clientWidth / 7;
    };
    this.$nextTick(() => {
      this.ceilWidth = this.$refs.taskView.clientWidth / 7;
      this.$refs.taskView.scrollTop = 960; // 滚动到早上8点的位置
    });
  },

  //销毁前
  beforeDestroy() {
    this.cancelMouseAction();
  },
  computed: {
    selectedDates() {
      if (this.isEditing) {
        let cron = this.getBaseItemCopyByCMID(this.list[this.selectedTaskIndex].CMID);
        let dates = [];
        for (let d of cron.day) {
          if (d != this.selectedTaskEditDate) {
            dates.push(d);
          }
        }
        dates.push(new Date(this.list[this.selectedTaskIndex].excuteTime * 1000).getDate());
        dates = Array.from(new Set(dates));
        dates.sort((a, b) => {
          return a - b;
        });
        return dates.toString() + '号';
      } else {
        return new Date(this.tempTask.time).getDate() + '号';
      }
    },
    selectedWeekDays() {
      if (this.isEditing && this.selectedTaskIndex > -1) {
        let cron = this.getBaseItemCopyByCMID(this.list[this.selectedTaskIndex].CMID);
        let dates = [];
        for (let d of cron.weekDay) {
          if (d != this.selectedTaskEditDay) {
            dates.push(d);
          }
        }
        dates.push(new Date(this.list[this.selectedTaskIndex].excuteTime * 1000).getDay());
        dates = Array.from(new Set(dates));
        dates.sort((a, b) => {
          return a - b;
        });
        let str = '';
        dates.forEach((i) => {
          str = str + this.getWeekDayCn2(i) + '、';
        });
        return str;
      } else {
        return this.getWeekDayCn2(this.tempTask.day);
      }
    },

    unit48() {
      let arr = [];
      for (let i = 0; i < 48; i++) {
        arr.push(i);
      }
      return arr;
    },

    rules() {
      let rules = {
        missionID: [{ required: true, message: '请选择任务', trigger: 'blur' }],
      };
      if (this.currentType == this.timerTypes.one) {
        rules.time = [{ required: true, message: '请选择执行时间', trigger: 'blur' }];
      } else if (this.currentType == this.timerTypes.day) {
        rules.time = [{ required: true, message: '请选择执行时间', trigger: 'blur' }];
        rules.startDate = [{ required: true, message: '请选择执行开始时间', trigger: 'blur' }];
        rules.endDate = [{ required: true, message: '请选择执行结束时间', trigger: 'blur' }];
      } else if (this.currentType == this.timerTypes.week) {
        rules.startDate = [{ required: true, message: '请选择执行开始时间', trigger: 'blur' }];
        rules.endDate = [{ required: true, message: '请选择执行结束时间', trigger: 'blur' }];
        rules.time = [{ required: true, message: '请选择执行结束时间', trigger: 'blur' }];
      } else if (this.currentType == this.timerTypes.month) {
        rules.time = [{ required: true, message: '请选择执行时间', trigger: 'blur' }];
        rules.startDate = [{ required: true, message: '请选择执行开始时间', trigger: 'blur' }];
        rules.endDate = [{ required: true, message: '请选择执行结束时间', trigger: 'blur' }];
      } else {
        rules = {};
      }
      return rules;
    },

    isSelectedDisabled() {
      if (this.selectedTaskIndex >= 0) {
        let item = this.list[this.selectedTaskIndex];
        if (item && item.type != timerTypes.one && item.isDisabled) {
          return true;
        }
      }
      return false;
    },

    isSelectedActive() {
      if (this.selectedTaskIndex >= 0) {
        let item = this.list[this.selectedTaskIndex];
        if (item && item.type != timerTypes.one && !item.isDisabled) {
          return true;
        }
      }
      return false;
    },

    // 选中的单元格样式，左，上style
    updateDeleteStyle() {
      return 'left:' + this.curentLeft + 'px;' + 'top:' + this.curentTop + 'px;';
    },
    // 横轴星期天日（当前星期几为第一位）
    weekDays() {
      let weeks = [];
      let oneDay = ONEDAY_SECOND_M; // 一天毫秒数
      for (let i = 0; i < 7; i++) {
        let times = new Date(this.currentDate + i * oneDay);
        let day = '';

        // todo ??? 生产环境注释取消
        // if (this.currentType == timerTypes.week) {
        day = this.getWeekDayCn(times.getDay()) + ' ';
        //   }
        let dates = this.getDateDay(times);
        let obj = {
          day: times.getDay(),
          time: times.getTime(),
          text: day + dates,
        };
        weeks.push(obj);
      }
      return weeks;
    },

    //获取0到24点
    clocks48() {
      let arr = [];
      for (let i = 0; i < 24; i++) {
        i = i < 10 ? '0' + i : i;
        arr.push(i + ':00');
        arr.push(i + ':30');
      }
      arr.push('24:00');
      return arr;
    },
  },

  filters: {
    indexTilte(index) {
      let hourEnd,
        minEnd,
        hour = Math.floor(index / 2),
        min = index % 2 ? 30 : 0;
      if (index % 2) {
        hourEnd = hour + 1;
        minEnd = 0;
      } else {
        hourEnd = hour;
        minEnd = 30;
      }
      hour = hour > 9 ? hour : '0' + hour;
      min = min > 9 ? min : '0' + min;
      hourEnd = hourEnd > 9 ? hourEnd : '0' + hourEnd;
      minEnd = minEnd > 9 ? minEnd : '0' + minEnd;
      return hour + ':' + min + '~' + hourEnd + ':' + minEnd;
    },
  },

  methods: {
    getSource(item) {
      let source = '未知来源';
      if (item.missionSource == 1) {
        source = '祺云下发';
      } else if (item.missionSource == 2) {
        source = '祺云定时计划任务';
      } else if (item.missionSource == 3) {
        source = '三方平台';
      }
      return '[' + source + ']';
    },
    setTempTaskByCMID(item) {
      if (!item) return;
      let CMID = item.CMID,
        hour = item.hour,
        min = item.min,
        day = item.day;
      if (CMID) {
        for (let cronb of this.baseList) {
          if (!cronb) {
            console.log(this.baseList);
            break;
          }
          if (cronb.CMID == CMID) {
            let cron = JSON.parse(JSON.stringify(cronb));
            this.tempTask = cron;
            this.currentType = cron.mode;
            if (cron.mode == this.timerTypes.one) {
              // 单次的
              this.onceDate = this.$moment(cron.excuteTime * 1000).format('YYYY-MM-DD');
              this.onceTime = (hour > 9 ? hour : '0' + hour) + ':' + (min > 9 ? min : '0' + min);
            } else {
              this.startToEnd = [cron.startDate * 10, cron.endDate * 10];
              console.log('============');
              this.onceTime = (hour > 9 ? hour : '0' + hour) + ':' + (min > 9 ? min : '0' + min);
              // 执行的开始时间与结束时间
              this.startToEnd = [cron.startDate * 1000, cron.endDate * 1000];
              console.log('============', this.startToEnd);
            }
          }
        }
      }
    },

    HSchangedHandler(hs) {
      let str = hs.split(':');
      let hh = parseInt(str[0]),
        mm = parseInt(str[1]);
      let time = new Date(this.tempTask.time);
      time.setHours(hh);
      time.setMinutes(mm);
      this.tempTask.hour = hh;
      this.tempTask.min = mm;
      this.tempTask.time = time.getTime();
    },

    async getSiteDetail() {
      const { code, data, reason } = await getSiteDetail(this.siteID);
      if (statusInclude(code)) {
        this.siteName = this.siteName + '【' + data.siteName + '】';
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },
    // 获取站点任务列表
    async getMissionList() {
      if (this.siteID) {
        const { code, data } = await getMissionBySiteId(this.siteID);
        this.missionList = data?.list || [];
        if (this.missionList.length == 0) {
          this.$message('该站点不存在任务数据！');
        }
      }
    },

    showHistoryScheduleInfo(s) {
      let schedule = JSON.parse(JSON.stringify(s));
      this.schedule = {};
      schedule.siteName = schedule.siteName ? schedule.siteName : '';
      schedule.UAVStartTime = schedule.UAVStartTime ? schedule.UAVStartTime : '';
      schedule.UAVEndTime = schedule.UAVEndTime ? schedule.UAVEndTime : '';
      schedule.createTime = schedule.createTime ? schedule.createTime : '';
      schedule.finishAction = schedule.finishAction ? schedule.finishAction : '';
      for (let prop in schedule) {
        schedule[prop] = schedule[prop] ? schedule[prop] : '未知';
      }
      schedule.UAVFlightMileage = schedule.UAVFlightMileage == '未知' ? 0 : schedule.UAVFlightMileage;
      schedule.UAVFlightTime = schedule.UAVFlightTime == '未知' ? 0 : schedule.UAVFlightTime;
      schedule.missionSource = schedule.missionSource ? schedule.missionSource : 1;
      if (schedule.missionSource == 1) {
        schedule.missionSource = '祺云下发';
      } else if (schedule.missionSource == 2) {
        schedule.missionSource = '祺云定时任务';
      } else if (schedule.missionSource == 3) {
        schedule.missionSource = '三方平台';
      }
      //   完成状态 1-待命 2-起飞准备中 3-执飞中 4-降落完成中 5-降落完成
      if (schedule.state == 5) {
        schedule.state = '成功';
      } else if (schedule.state == -1) {
        schedule.state = '失败';
      } else if (schedule.state == 1) {
        schedule.state = '未执行';
      } else {
        schedule.state = '进行中';
      }
      this.schedule = schedule;
      console.log(schedule);
      this.dialogVisible2 = true;
    },

    scheduleDialogClose() {
      this.dialogVisible2 = false;
    },

    async getMissionInfo(id) {
      if (id) {
        const { code, data } = await getMissionDetail(id);
        console.log(data);
      }
    },

    async getHistoryList() {
      let endTime = new Date().getTime();
      let currendEnd = this.currentDate + ONEDAY_SECOND_M * 7;
      endTime = endTime < currendEnd ? endTime : currendEnd;
      const { code, data, reason } = await getHistoryListByTimeAndSID(this.siteID, this.$moment(this.currentDate).format('YYYY-MM-DD HH:mm:ss'), this.$moment(endTime).format('YYYY-MM-DD HH:mm:ss'));
      if (statusInclude(code)) {
        //  this.baseList = []
        if (data?.list) {
          this.renderInvalidListByHistory(data.list);
        }
      } else {
        //  this.$message({ type: 'error', message: reason });
      }
    },

    // 获取定时器列表
    async getTimerList() {
      let endTime = this.currentDate + ONEDAY_SECOND_M * 7; // 当前七天最后一天+1零点
      const { code, data, reason } = await getTimerListByTimeAndSID(parseInt(this.currentDate / 1000), parseInt(endTime / 1000), this.siteID);
      if (statusInclude(code)) {
        this.baseList = [];
        this.list = [];
        if (data?.list) {
          this.baseList = data.list;
          this.getListByCrons(JSON.parse(JSON.stringify(this.baseList)));
        }
      } else {
        this.$message({ type: 'error', message: reason });
      }
    },

    // 根据历史数据转换成当前的数据格式
    renderInvalidListByHistory(items) {
      this.invalidList = [];
      if (items.length == 0) return;
      items.forEach((element) => {
        let excuteTime = new Date(element.createTime);
        element.min = element.min;
        let obj = {};

        // 测试
        let oneMinMileSeconds = 1000 * 60;
        obj.name = element.missionName;
        obj.type = this.timerTypes.one;
        obj.excuteTime = excuteTime.getTime();
        obj.hour = excuteTime.getHours();
        obj.day = excuteTime.getDay();
        obj.min = excuteTime.getMinutes();
        obj.state = element.state;
        obj.hour_origin = obj.hour;
        obj.min_origin = element.min;
        obj.missionID = element.missionID;
        obj.missionSource = element.missionSource;
        if (element.UAVEndTime) {
          let hour = new Date(element.UAVEndTime).getHours(),
            min = new Date(element.UAVEndTime).getMinutes();
          hour = hour > 9 ? hour : '0' + hour;
          min = min > 9 ? min : '0' + min;
          obj.endTime = '~' + hour + ':' + min;
          let ht = Math.ceil((new Date(element.UAVEndTime).getTime() - obj.excuteTime) / oneMinMileSeconds);
          obj.ht = ht > 9 ? ht : 10;
        }
        this.invalidList.push(obj);
      });
    },

    getListByCrons(crons) {
      this.list = [];
      if (crons.length == 0) return;
      let list = [];
      crons.forEach((element) => {
        let excuteList = element.excuteList;
        if (excuteList && excuteList.length > 0) {
          excuteList.forEach((el) => {
            if (el.isExcute == 0) {
              let obj = this.refactorCron(el, element);
              list.push(obj);
            }
          });
        }
      });
      this.list = list;
    },

    refactorCron(cron, base) {
      let obj = {},
        excuteDate = new Date(cron.excuteTime * 1000);
      obj.name = base.missionName;
      obj.isDisabled = cron.isDisabled;
      obj.type = base.mode;
      obj.hour = base.hour;
      obj.min = base.min;
      obj.CMID = base.CMID;
      obj.excuteTime = cron.excuteTime;
      obj.day = excuteDate.getDay();
      obj.date = excuteDate.getDate();
      obj.day_origin = obj.day;
      obj.hour_origin = excuteDate.getHours();
      obj.min_origin = excuteDate.getMinutes();
      obj.hour = obj.hour_origin;
      obj.min = obj.min_origin;

      return obj;
    },
    // 禁用
    disabledOne() {
      if (this.selectedTaskIndex < 0) return;
      this.$confirm('确定禁用该计划任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let target = this.list[this.selectedTaskIndex];
        this.disabledOneAjax(target.CMID, target.excuteTime);
        this.delDialogVisible = false;
      });
    },

    async disabledOneAjax(CMID, excuteTime) {
      let { code, data, reason } = await deleteTimer(CMID, excuteTime);
      if (statusInclude(code)) {
        this.selectedTaskIndex = -1;
        this.cancelHilightTaskSelected();
        this.getHistoryList();
        this.getTimerList();
        this.$message({
          type: 'success',
          message: '禁用成功!',
        });
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },

    // 激活
    activeOne() {
      if (this.selectedTaskIndex < 0) return;
      let target = this.list[this.selectedTaskIndex];
      this.$confirm('确定激活该计划任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.activeAction(target.CMID, target.excuteTime);
      });
    },

    deleteOne() {
      if (this.selectedTaskIndex < 0) return;
      let target = this.list[this.selectedTaskIndex];
      if (target.type == timerTypes.one) {
        this.$confirm('确定删除该计划任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.deleteAction();
          })
          .catch(() => {
            this.selectedTaskIndex = -1;
            this.cancelHilightTaskSelected();
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.delDialogVisible = true;
      }
    },

    delDialogClose() {
      this.delDialogVisible = false;
      this.selectedTaskIndex = -1;
      this.cancelHilightTaskSelected();
      this.$message({
        type: 'info',
        message: '已取消删除',
      });
    },

    changeDeleteSingle(t) {},

    async deleteAction() {
      if (this.selectedTaskIndex < 0) return;
      this.delDialogVisible = false;
      let target = this.list[this.selectedTaskIndex];
      let id = target.CMID;
      let excuteTime = 0;
      if (target.type != timerTypes.one) {
        if (!this.deleteAll) {
          // 删除单个
          excuteTime = target.excuteTime;
        }
      }
      console.log(target);
      let { code, reason } = await deleteTimer(id, excuteTime);
      if (statusInclude(code)) {
        this.selectedTaskIndex = -1;
        this.cancelHilightTaskSelected();
        this.getHistoryList();
        this.getTimerList();
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        this.currentType = null;
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },

    async activeAction(CMID, excuteTime) {
      let { code, data, reason } = await activeTimer(CMID, excuteTime);
      if (statusInclude(code)) {
        this.selectedTaskIndex = -1;
        this.cancelHilightTaskSelected();
        this.getHistoryList();
        this.getTimerList();
        this.$message({
          type: 'success',
          message: '激活成功!',
        });
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
      this.delDialogVisible = false;
    },

    async activeAction(CMID, excuteTime) {
      let { code, data, reason } = await activeTimer(CMID, excuteTime);
      if (statusInclude(code)) {
        this.selectedTaskIndex = -1;
        this.cancelHilightTaskSelected();
        this.getHistoryList();
        this.getTimerList();
        this.$message({
          type: 'success',
          message: '激活成功!',
        });
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },

    // 编辑，开启该单元格拖拽
    beginEdit() {
      this.isEditing = true;
    },
    // 更新每天类型的任务的day,hour,min
    updateDailyTasksByCMID(id, day, hour, min) {
      let dayAdd = day;
      for (let item of this.list) {
        if (item.CMID == id) {
          item.day = dayAdd;
          item.hour = hour;
          item.min = min;
          item.day_origin = dayAdd;
          item.hour_origin = hour;
          item.min_origin = min;
          dayAdd++;
        }
      }
    },

    initData() {
      this.tempTask = {
        siteID: '',
        mode: 1, // 对应后台mode
        day: 3, //每月几号
        weekDay: 1, // 周几
        hour: 11,
        min: 30,
        end: false,
        time: null, // 执行时间
        content: '',
        startDate: '',
        endDate: '',
        missionID: null,
        name: null,
      };
      this.adding = false;
      this.currentType = null;
      this.isEditing = false;
      this.selectedTaskIndex = -1;
    },

    async updateTimer(list) {
      let { code, data, reason } = await updateTimer(list);
      if (statusInclude(code)) {
        this.$message({
          type: 'success',
          message: '编辑成功!',
        });

        this.dialogVisible = false;
        this.cancelHilightTaskSelected();
        let id = list.CMID;
        for (let i = 0; i < this.baseList.length; i++) {
          if (this.baseList[i].CMID == id) {
            this.baseList[i] = data;
            break;
          }
        }
        if (this.currentType != timerTypes.day) {
          for (let item of this.list) {
            if (item.CMID == id) {
              item.hour = list.hour;
              item.min = list.min;
              item.hour_origin = list.hour;
              item.min_origin = list.min;
            }
          }
        }
        this.initData();
        delete data.excuteList;
        delete data.time;
        // this.getListByCrons(JSON.parse(JSON.stringify(this.baseList)));
        this.getHistoryList();
        this.getTimerList();
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },

    getBaseItemCopyByCMID(id) {
      let data = null;
      for (let item of this.baseList) {
        if (item.CMID == id) {
          data = JSON.parse(JSON.stringify(item));
          break;
        }
      }

      console.log(data);
      return data;
    },

    // 下一步
    doNext() {
      // 计算可以微调的时间区间
      let obj = {
        day: this.list[this.selectedTaskIndex].day, // 周几
        hour: this.list[this.selectedTaskIndex].hour,
        min: this.list[this.selectedTaskIndex].min,
      };
      let resArr = this.calculateAvailableTimes(obj);
      if (resArr) {
        let start = resArr[0];
        if (start.hour == obj.hour && start.min > obj.min) {
          obj.min = start.min;
        }
        this.onceTime = (obj.hour > 9 ? obj.hour : '0' + obj.hour) + ':' + (obj.min > 9 ? obj.min : '0' + obj.min);
        let condi = `.title-week[data-day='${obj.day.toString()}']`;
        let tar = document.querySelector(condi);
        let dateset = tar.dataset;
        let date = new Date(parseInt(dateset.time));
        date.setSeconds(0);
        date.setMinutes(obj.min);
        date.setHours(obj.hour);
        this.onceDate = this.$moment(date).format('YYYY-MM-DD');
        this.tempTask.time = date.getTime() / 1000;
      }
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },

    doUpateTimer() {
      let target = this.list[this.selectedTaskIndex];
      let id = target.CMID;
      // let data = this.getBaseItemCopyByCMID(id);
      let data = JSON.parse(JSON.stringify(this.tempTask));
      data.excuteTime = data.time;
      delete data.excuteList;
      delete data.time;
      data.day = [];
      data.weekDay = [];
      let cron = this.getBaseItemCopyByCMID(this.list[this.selectedTaskIndex].CMID);
      // 执行区间
      data.startDate = this.startDate / 1000;
      data.endDate = (this.endDate + (ONEDAY_SECOND_M - 1000)) / 1000; // 结束天的23h59m59s
      let dates = [];
      if (data.mode == timerTypes.week) {
        for (let d of cron.weekDay) {
          if (d != this.selectedTaskEditDay) {
            dates.push(d);
          }
        }
        dates.push(new Date(this.list[this.selectedTaskIndex].excuteTime * 1000).getDay());
        data.weekDay = dates;
      } else if (data.mode == timerTypes.month) {
        for (let d of cron.day) {
          if (d != this.selectedTaskEditDate) {
            dates.push(d);
          }
        }
        dates.push(new Date(this.list[this.selectedTaskIndex].excuteTime * 1000).getDate());
        dates = Array.from(new Set(dates));
        data.day = dates;
      }
      if (!data) {
        console.error('查无此对象！');
        return;
      }
      this.updateTimer(data);
    },

    doCancel() {
      let target = document.getElementsByClassName('job-hilight')[0];
      if (target) {
        this.editRecoverPostion(target);
      }
      this.cancelHilightTaskSelected();
      this.selectedTaskIndex = -1;
      this.isEditing = false;
      this.currentType = null;
      this.clearMatchedView();
    },

    // 当天零点时间戳
    get0ClockTimeStempToday(time = new Date()) {
      let year = time.getFullYear(),
        monthIndex = time.getMonth(),
        date = time.getDate();
      return new Date(year, monthIndex, date, 0, 0, 0, 0).getTime();
    },

    // 根据时分与零点的时间戳获取新的时间戳
    getPreciseByHourAndMin(time, hour, min) {
      let date = new Date(time + hour * 60 * 60 * 1000 + min * 60 * 1000).getTime();
      return date;
    },

    getAddData() {
      let match = this.missionList.filter((item) => {
        return item.missionID == this.tempTask.missionID;
      });
      this.tempTask.name = match[0].missionName;
      let obj = {
        hour: -1,
        min: -1,
        excuteTime: -1,
        weekDay: [],
        day: [],
        siteID: this.siteID,
        mode: this.currentType,
        missionName: this.tempTask.name,
        missionID: this.tempTask.missionID,
        content: this.tempTask.content,
      };
      if (this.currentType == timerTypes.one) {
        obj.excuteTime = this.tempTask.time / 1000;
      } else {
        obj.startDate = this.startDate / 1000;
        obj.endDate = (this.endDate + (ONEDAY_SECOND_M - 1000)) / 1000; // 结束天的23h59m59s
        obj.hour = this.tempTask.hour;
        obj.min = this.tempTask.min;
        if (this.currentType == timerTypes.day) {
        } else if (this.currentType == timerTypes.week) {
          obj.weekDay = [this.tempTask.day];
        } else if (this.currentType == timerTypes.month) {
          obj.day = [this.tempTask.date];
        }
      }
      return obj;
    },

    async addTimer(obj) {
      let { code, data, reason } = await addTimer(obj);
      if (statusInclude(code)) {
        this.getHistoryList();
        this.getTimerList();
        this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '添加成功!',
        });

        this.initData();
      } else {
        this.$message({
          type: 'error',
          message: reason,
        });
      }
    },

    // 确认按钮
    sendData() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.isEditing) {
            this.doUpateTimer();
          } else {
            let obj = this.getAddData();
            this.addTimer(obj);
          }
        } else {
          return false;
        }
      });
    },

    // 取消时间的选择
    cancelData() {
      this.dialogVisible = false;
      if (!this.isEditing) {
        this.list.pop();
        this.tempTaskClear();
      }
    },

    tempTaskClear() {
      for (let prop in this.tempTask) {
        this.tempTask[prop] = null;
      }
    },

    // 根据数值返回周几
    getWeekDayCn(n) {
      const weekMap = {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
      };
      return weekMap[n];
    },

    getWeekDayCn2(n) {
      let weekMap = {
        0: '日',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
      };
      return weekMap[n];
    },

    // 设置当前的类型（天周月等）
    setCurretnType(type) {
      if (this.adding) {
        this.currentType = type;
      }
    },

    // 向前或者向后翻页
    prePage(t) {
      // this.doCancel()
      this.list = [];
      this.invalidList = [];
      if (t) {
        // 前翻页
        this.currentDate = this.currentDate - 7 * ONEDAY_SECOND_M;
      } else {
        //后翻页
        this.currentDate = this.currentDate + 7 * ONEDAY_SECOND_M;
      }
      if (this.currentDateInit > this.currentDate) {
        this.addAble = false;
        this.adding = false;
      } else {
        this.addAble = true;
      }
      let nowTime = new Date().getTime();
      if (nowTime >= this.currentDate + 7 * ONEDAY_SECOND_M) {
        // 所有都是过去的日期
        this.getHistoryList();
      } else if (nowTime <= this.currentDate) {
        // 所有都是将来的日期
        this.getTimerList();
      } else {
        //存在过去与将来的日期
        this.getHistoryList();
        this.getTimerList();
      }
    },

    // 鼠标点击新建或取消改变类型
    setCurretnTypeByAddBtn() {
      if (this.isEditing) {
        this.doCancel();
      }
      if (this.addAble) {
        if (!this.adding) {
          this.currentType = this.timerTypes.one;
        } else {
          this.currentType = null;
        }
        this.adding = !this.adding;
      }
    },

    // 点击区域
    clickTaskViews(e) {
      if (this.adding) {
        if (e.target.className.indexOf('job-one-ceil') > -1) {
          // 点击了基础单元格
          let dataset = e.target.dataset;
          let start = parseInt(dataset.start),
            time = parseInt(dataset.time),
            day = parseInt(dataset.day),
            hour = Math.floor(start / 2);
          let min = start % 2 ? 30 : 0;
          // 如果是过去的时间，那么已经不允许点击了，此时不必判断
          // 单次任务不得小于当前时间
          if (this.currentType == timerTypes.one) {
            let now = new Date();
            if (day == now.getDay()) {
              // 同一天
              if (hour < now.getHours() || (hour == now.getHours() && min <= now.getMinutes())) {
                this.$message({
                  type: 'error',
                  message: '无效的时间段！',
                });
                return false;
              }
            }
          }
          let obj = {
            type: this.currentType,
            day: day, // 周几
            hour: hour,
            min: min,
            time: this.getPreciseByHourAndMin(time, hour, min),
            name: '',
          };
          this.onceDate = this.$moment(obj.time).format('YYYY-MM-DD');
          let now = new Date(),
            objDate = new Date(obj.time);
          // if (this.currentType == timerTypes.day) {
          //   this.startToEnd = [this.get0ClockTimeStempToday(objDate), this.endDate];
          // } else {
          //   this.startToEnd = [this.get0ClockTimeStempToday(now), this.endDate];
          // }
          if (this.currentType != timerTypes.week) {
            this.tempTask.date = new Date(time).getDate();
          }
          // 计算可以微调的时间区间
          let resArr = this.calculateAvailableTimes(obj);
          if (resArr) {
            let start = resArr[0];
            if (start.hour == obj.hour && start.min > obj.min) {
              obj.min = start.min;
            }
            Object.assign(this.tempTask, obj);
            this.$nextTick(() => {
              this.list.push(obj);
              this.datatimeSelected = parseInt(dataset.time);
              this.onceTime = (obj.hour > 9 ? obj.hour : '0' + obj.hour) + ':' + (obj.min > 9 ? obj.min : '0' + obj.min);
              this.dialogVisible = true;
            });
          }
        }
      } else {
        if ((e.target.className.indexOf('task-clock-one') > -1 || e.target.parentNode.className.indexOf('task-clock-one') > -1) && this.selectedTaskIndex == -1) {
          // 存在的任务
          let target = e.target;
          if (e.target.parentNode.className.indexOf('task-clock-one') > -1) {
            target = e.target.parentNode;
          }
          // 过期的任务不允许操作
          if (target.className.indexOf('task-invalid') > -1) {
            this.showHistoryScheduleInfo(this.invalidList[parseInt(target.dataset.index)]);
            return;
          }
          let index = parseInt(target.dataset.index);
          this.selectedTaskIndex = index;
          this.selectedTaskEditDay = parseInt(target.dataset.day);
          this.selectedTaskEditDate = parseInt(target.dataset.date);
          let c = this.list[index];
          this.setTempTaskByCMID(c);
          this.hilightTaskSelected(target);
          let curentLeft = parseInt(target.style.left.split('px')[0]) + e.offsetX + 20;
          let curentTop = parseInt(target.style.top.split('px')[0]) + e.offsetY;
          let maxLeft = this.ceilWidth * 7 - 50,
            maxTop = 60 * 48 - 120;
          curentLeft = curentLeft > maxLeft ? maxLeft : curentLeft;
          curentTop = curentTop > maxTop ? maxTop : curentTop;
          this.curentLeft = curentLeft;
          this.curentTop = curentTop;
        }
      }
    },

    editRecoverPostion(target) {
      let dataset = target.dataset;
      let index = parseInt(dataset.index);
      if (this.list[index]) {
        let item = this.getBaseItemCopyByCMID(this.list[index].CMID);
        if (item) {
          let item1 = this.list[index];
          let hour = item1.hour_origin,
            min = item1.min_origin,
            day = item1.day_origin,
            type = item.mode;
          let obj = {
            hour,
            min,
            day,
            type,
          };
          target.style = this.getStyle(obj);
          this.list[index].hour = hour;
          this.list[index].min = min;
        }
      }
    },

    // 获取月日 如:09/25
    getDateDay(date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear() + '/';
      let date1 = date.getDate();
      month = month > 9 ? month : '0' + month;
      date1 = date1 > 9 ? date1 : '0' + date1;
      return year + month + '/' + date1;
    },

    // 不同类型的颜色
    getBaseClassByType(item) {
      let type = item.type,
        typebase;
      switch (type) {
        case timerTypes.one:
          typebase = 'type-one';
          break;
        case timerTypes.day:
          typebase = 'type-day';
          break;
        case timerTypes.week:
          typebase = 'type-week';
          break;
        case timerTypes.month:
          typebase = 'type-month';
          break;
      }
      let classes = typebase + '  task-clock-one';
      if (item.isDisabled) {
        // 不可用，被禁用
        classes = classes + ' task-disabled';
      }
      return classes;
    },

    // 计算定位
    getStyle(obj) {
      let px = 2,
        style = 'position:absolute;',
        hour = obj.hour,
        min = obj.min,
        targetDay = obj.day,
        type = obj.type;
      style = style + 'top:' + (hour * 120 + min * px) + 'px;';
      let currentWeekDay = new Date(this.currentDate).getDay(); // 当天的星期几
      let offset = targetDay - currentWeekDay;
      //比当天小加7
      if (targetDay < currentWeekDay) offset += 7;
      style = style + 'left:' + offset * this.ceilWidth + 'px;';
      style = style + 'width:' + this.ceilWidth + 'px;';
      if (obj.ht) {
        style = style + 'height:' + obj.ht * 2 + 'px;';
      }
      return style;
    },

    initMouseAction() {
      let that = this;
      this.$refs.taskView.onmousedown = (e) => {
        let target = e.target;
        if (e.target.nodeName.toLowerCase() == 'p') {
          target = e.target.parentNode;
        }
        let index = parseInt(target.dataset.index);

        if (that.isEditing) {
          if (target.dataset.type) {
            if (target.dataset.type == that.unitType.free) {
            } else if (target.dataset.type == that.unitType.task && index == that.selectedTaskIndex) {
              target.leftOffset = e.offsetX;
              target.topOffset = e.offsetY;
              that.editTarget = target;
              that.editTarget.style.cursor = 'move';
            }
          }
        }
      };
      this.$refs.taskView.onmousemove = (e) => {
        e.stopPropagation();
        e.cancelBubble = true;
        let x = e.clientX,
          y = e.clientY;
        if (that.isEditing && that.editTarget) {
          let left = x - that.baseLeft - that.editTarget.leftOffset,
            top = y - that.baseTop - that.editTarget.topOffset + that.$refs.scrollView.scrollTop;
          left = left < 0 ? 0 : left;
          left = left > 6 * this.ceilWidth ? 6 * this.ceilWidth : left;
          let style = 'position:absolute;left:' + left + 'px;';
          style = style + 'top:' + top + 'px;';
          style = style + 'width:' + that.ceilWidth + 'px;';
          that.editTarget.style = style;
          that.clearMatchedView();
          that.taskCollisonDetector(left, top);
        }
      };
      this.$refs.taskView.onmouseup = (e) => {
        // 针对编辑位置的区块，鼠标放下,若是符合条件的区块直接移动至该匹配位置，否则恢复到原来位置，并滚动
        if (that.isEditing) {
          if (that.editTargetMatchObj) {
            //list[index]
            that.list[that.selectedTaskIndex].hour = that.editTargetMatchObj.hour;
            that.list[that.selectedTaskIndex].min = that.editTargetMatchObj.min;
            that.list[that.selectedTaskIndex].day = that.editTargetMatchObj.day;
            // tempTask
            that.tempTask.time = that.editTargetMatchObj.time;
            that.tempTask.hour = that.editTargetMatchObj.hour;
            that.tempTask.min = that.editTargetMatchObj.min;
            that.tempTask.day = that.editTargetMatchObj.day;

            that.list[that.selectedTaskIndex].excuteTime = that.editTargetMatchObj.time / 1000;
            if (that.editTarget) {
              that.datatimeSelected = parseInt(that.editTarget.dataset.time);
              that.editTarget.style = that.getStyle(that.editTargetMatchObj);
            }
          } else {
            that.editTarget.style = that.getStyle(that.list[that.selectedTaskIndex]);
          }
        }
        that.editTarget = null;
      };
    },

    hilightTaskSelected(target) {
      if (target) {
        target.className = target.className + ' job-hilight';
      }
    },

    cancelHilightTaskSelected() {
      let arr = Array.from(document.getElementsByClassName('job-hilight'));
      for (let i = 0; i < arr.length; i++) {
        arr[i].className = arr[i].className.replace('job-hilight', '');
      }
    },

    cancelMouseAction() {
      this.$refs.taskView.onmousedown = null;
      this.$refs.taskView.onmouseup = null;
      this.$refs.taskView.onmousemove = null;
    },

    clearMatchedView() {
      let arr = document.getElementsByClassName('best-match');
      arr = Array.from(arr);
      for (let item of arr) {
        item.className = item.className.replace(' best-match', '');
      }
    },

    getTasksByDay(day) {
      // 该列所有任务
      let tasks = document.getElementsByClassName('task-clock-one');
      let targetTasks = []; // 同一列
      tasks = Array.from(tasks);
      for (let task of tasks) {
        let index = parseInt(task.dataset.index);
        // 剔除自己（正挪动的块）或不在一列的
        if (index == this.selectedTaskIndex) continue;
        let dayT = parseInt(task.dataset.day);
        if (dayT == day) {
          targetTasks.push(task);
        }
      }
      return targetTasks;
    },

    // 移动块的碰撞检测,div左上角xy   类名：.task-clock-one
    taskCollisonDetector(x, y) {
      let matchedObj = null;
      //找寻最匹配、最近的单元格
      let left = Math.floor(x / this.ceilWidth);
      if (x % this.ceilWidth > this.ceilWidth / 2) left += 1;
      let top = Math.floor(y / 60);
      // top,left 行列的区块
      let expired = false;
      if (left == 0) {
        // 今天的
        let hour = Math.floor(top / 2);
        let min = Math.floor(top % 2);
        if (min > 0) {
          min = 30;
        }
        let nowHour = new Date().getHours(),
          nowMin = new Date().getMinutes();
        if (hour < nowHour || (hour == nowHour && min > nowMin)) {
          expired = true;
        }
      }
      let matchDom = document.querySelector('div.row-' + top + '.column-' + left);
      let matchedhour = parseInt(matchDom.dataset.hour);
      if (matchDom && !expired) {
        let dataset = matchDom.dataset;
        let start = parseInt(dataset.start);
        let time = parseInt(dataset.time),
          day = parseInt(dataset.day),
          hour = Math.floor(start / 2);
        let min = start % 2 ? 30 : 0;
        matchedObj = {
          type: this.list[this.selectedTaskIndex].type,
          day: day, // 周几
          hour: hour,
          min: min,
          time: this.getPreciseByHourAndMin(time, hour, min),
          name: this.list[this.selectedTaskIndex].name,
        };
        //匹配到的单元格 style.top
        let matchedDomTop = hour * 120 + min * 2;
        // 该列所有任务 同一天
        let compareTasks = this.getTasksByDay(day);
        // 检测单元格是否被占用
        for (let task of compareTasks) {
          // 宽相等，只需判断任务上部或下部在此单元格内部即为被占用
          let itemTop = parseInt(task.style.top.split('px')[0]);
          if (itemTop >= matchedDomTop && itemTop < matchedDomTop + 60) {
            matchedObj = null;
          } else if (itemTop < matchedDomTop && itemTop + 60 > matchedDomTop) {
            // 取下一格
            matchedObj = null;
          }
        }
        if (matchedObj) {
          let obj = this.getHourMinByIndex(top);
          matchedObj.hour = obj.hour;
          matchedObj.min = obj.min;
          let className = matchDom.className;
          if (className.indexOf('best-match') == -1) {
            matchDom.className = matchDom.className + ' best-match';
          }
        }
      }
      this.editTargetMatchObj = matchedObj;
    },

    getHourMinByIndex(index) {
      let obj = null;
      if (index >= 0 && index < 48) {
        obj = {
          hour: Math.floor(index / 2),
          min: index % 2 ? 30 : 0,
        };
      }
      return obj;
    },

    // 计算当前可以微调的时间区间,不满足条件的禁止选中，返回null
    calculateAvailableTimes(obj) {
      let { hour, min, day } = obj;
      // 同一列
      let compareTasks = this.getTasksByDay(day);
      //此时间段存在前后时间任务的，不存在则，0点，23:59
      let currentStart = {
          hour: hour,
          min: min,
        },
        currentEnd = {
          hour: min == 0 ? hour : hour + 1,
          min: min == 0 ? 30 : 0,
        };
      let matchedStart = null,
        matchedEnd = null;
      for (let task of compareTasks) {
        // 默认占据30分钟的时间
        let compareStartHour = parseInt(task.dataset.hour),
          compareStartMin = parseInt(task.dataset.min),
          day = parseInt(task.dataset.day);
        let compareEndHour = compareStartHour,
          compareEndMin = compareStartMin + 30;
        if (compareEndMin >= 60) {
          ++compareEndHour;
          compareEndMin -= 60;
        }
        // 此时选中的开始时间，下面半个小时有其他任务，禁止选择
        if (compareStartHour == currentStart.hour) {
          if ((currentStart.min == 0 && compareStartMin > 0 && compareStartMin < 30) || (currentStart.min == 30 && compareStartMin > 30 && compareStartMin < 60)) {
            return false;
          }
        }
        //开始时间
        if (
          compareEndHour < currentStart.hour ||
          (compareEndHour == currentStart.hour && compareEndMin <= currentStart.min) ||
          // 交叉的情况
          (compareEndHour == currentStart.hour && compareEndMin > currentStart.min && (compareStartHour < currentStart.hour || (compareStartHour == currentStart.hour && compareStartMin < currentStart.min)))
        ) {
          if (!matchedStart) {
            matchedStart = {
              hour: compareEndHour,
              min: compareEndMin,
            };
          } else if (compareEndHour > matchedStart.hour || (compareEndHour == matchedStart.hour && compareEndMin >= matchedStart.min)) {
            matchedStart.hour = compareEndHour;
            matchedStart.min = compareEndMin;
          }
        }
        //结束时间,  // 交叉的情况在上面已经returnm无需判断交叉问题
        if (compareStartHour > currentEnd.hour || (compareStartHour == currentEnd.hour && compareStartMin >= currentEnd.min)) {
          if (!matchedEnd) {
            matchedEnd = {
              hour: compareStartHour,
              min: compareStartMin,
            };
          } else if (compareStartHour < matchedEnd.hour || (compareStartHour == matchedEnd.hour && compareStartMin <= matchedEnd.min)) {
            matchedEnd.hour = compareStartHour;
            matchedEnd.min = compareStartMin;
          }
        }
      }
      if (!matchedStart) {
        matchedStart = {
          hour: 0,
          min: 0,
        };
      }
      if (!matchedEnd) {
        matchedEnd = {
          hour: 23,
          min: 59,
        };
      }
      // 不满足至少30分钟，禁止选择
      if (matchedStart && matchedEnd) {
        let startmins = matchedStart.hour * 60 + matchedStart.min,
          endmins = matchedEnd.hour * 60 + matchedEnd.min;
        let offset = endmins - startmins;
        if (offset < 30) {
          return false;
        } else {
          let start = new Date(obj.time);
          start.setHours(matchedStart.hour);
          start.setMinutes(matchedStart.min);
          let end = new Date(obj.time);
          end.setHours(matchedEnd.hour);
          end.setMinutes(matchedEnd.min);
          this.HMStart = (matchedStart.hour > 9 ? matchedStart.hour : '0' + matchedStart.hour) + ':' + (matchedStart.min > 9 ? matchedStart.min : '0' + matchedStart.min);
          this.HMStop = (matchedEnd.hour > 9 ? matchedEnd.hour : '0' + matchedEnd.hour) + ':' + (matchedEnd.min > 9 ? matchedEnd.min : '0' + matchedEnd.min);
        }
      }
      // 当天已经失效的再次过滤
      let now = new Date();
      let nowDay = now.getDay(),
        nowHour = now.getHours(),
        nowMin = now.getMinutes();
      if (nowDay == now) {
        if (matchedStart.hour < nowHour) {
          matchedStart.hour = nowHour;
        } else if (matchedStart.hour == nowHour && matchedStart.min <= nowMin) {
          matchedStart.min = nowMin;
        }
      }
      return [matchedStart, matchedEnd];
    },
  },
};
</script>

<style scoped lang="scss">
$borderColor: #595b5e;
$borderColorLight: white;
$fontColor: #409eff;
.schedule span {
  color: white;
  display: inline-block;
  line-height: 35px;
}
.best-match {
  border: 1px solid white !important;
}
.offset-label {
  margin-left: 10px;
}
.update-delete {
  position: absolute;
  width: auto;
  height: auto;
  display: flex;
  top: 50%;
  left: 50%;
  flex-direction: column;
  text-align: center;
  background: white;
  border-radius: 4px;
  border: 1px solid $fontColor;
  z-index: 20;
  > div {
    width: 45px;
    height: 40px;
    line-height: 40px;
    color: $fontColor;
    cursor: pointer;
    font-size: 14px;
    border-bottom: 1px solid;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.type-selected {
  border: 1px solid white;
  transform: scale(1.4);
}
.task-invalid-block {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
}
.task-invalid {
  background-color: #c0c4cc !important;
  color: #403e3e !important;
  p {
    span {
      font-size: 12px;
      font-weight: bold;
    }
    .task-time {
      color: #403e3e;
    }
    .task-source {
      color: #409eff;
    }
    .task-success {
      color: #36c549;
    }
    .task-fail {
      color: red;
    }
  }
}
.task-clock-one {
  color: white;
  font-size: 12px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid $borderColor;
  border-right: 0;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  > p {
    line-height: 20px;
    margin: auto;
    overflow: hidden;
    max-height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.task-disabled {
  opacity: 0.5 !important;
}
.clock-unit {
  color: white;
  font-size: 12px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.type-one {
  background: pink;
}
.type-day {
  background: #69e2b7;
}
.type-week {
  background: #274cde;
}
.type-month {
  background: #7470e1;
}
.type-none {
  opacity: 0.5 !important;
  cursor: not-allowed;
  color: black;
}
.main {
  user-select: none;
  width: 100%;
  height: 100%;
  background-color: $navBarBg;
  .tools {
    padding-left: 50px;
    width: 100%;
    height: 80px;
    display: flex;
    .button {
      width: 80px;
      height: 40px;
      border: 0;
      cursor: pointer;
      color: white;
      background: $primary;
      border-radius: 4px;
      margin-right: 20px;
    }
    .forbidden {
      cursor: not-allowed;
    }
    .pre-back {
      height: 100%;
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      margin-top: 5px;
      > img {
        width: 32px;
        color: $menuActiveText;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    > div {
      display: flex;
      margin: auto auto auto 0;
    }
    .color-set {
      position: relative;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      > div {
        width: 45px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
        color: white;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .timer {
    width: calc(100% - 100px);
    min-width: 1000px;
    margin: auto;
    height: calc(100% - 130px);
    border: 1px solid $borderColor;
    position: relative;
    .title {
      display: flex;
      flex-direction: row;
      padding-right: 5px;
      background: $primary;
      border: 1px solid $borderColor;
      border-bottom: 0;
      .title-week {
        flex: 1;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        background-color: $primary;
        border: 1px solid $borderColor;
        color: white;
        text-align: center;
        border-bottom: 0;
        border-right: 0;
        border-top: 0;
      }
      .title-week:first-child {
        border: 0;
      }
    }
    .all {
      position: relative;
      width: calc(100% + 40px);
      height: calc(100% - 40px);
      overflow-y: scroll;
      margin-left: -40px;
      .times {
        position: absolute;
        height: auto;
        left: 0;
        top: -25px;
        width: 40px;
      }
      .jobs {
        position: absolute;
        width: calc(100% - 40px);
        height: auto;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        .job-one {
          display: flex;
          flex-direction: row;
          height: 60px;
          .job-one-ceil {
            flex: 1;
            border: 1px solid $borderColor;
            border-right: 0;
            border-bottom: 0;
            box-sizing: border-box;
          }
        }
        .job-one:nth-last-child(2) {
          border: 1px solid $borderColor;
          border-left: 0;
          border-right: 0;
          border-top: 0;
        }
      }
    }
  }
}

.job-hilight {
  border: 2px solid yellow;
  z-index: 10;
}
</style>
